export default {
  operational: {
    name: 'Operational',
    colour: '#247234',
    backgroundColour: 'rgba(61, 167, 81, 0.1)',
  },
  performanceIssues: {
    name: 'Performance Issues',
    colour: '#2f5888',
    backgroundColour: 'rgba(73, 144, 226, 0.1)',
  },
  partialOutage: {
    name: 'Partial Outage',
    colour: '#74582a',
    backgroundColour: 'rgba(255, 198, 103, 0.1)',
  },
  majorOutage: {
    name: 'Major Outage',
    colour: '#8e3b31',
    backgroundColour: 'rgba(217, 68, 48, 0.1)',
  },
  unknown: {
    name: 'Unknown',
    colour: '#6e6b6b',
    backgroundColour: 'rgba(177, 177, 177, 0.1)',
  },
}
