import React from 'react'
import Skeleton from './skeleton'
import Component from './component'
import useDidMount from '../useDidMount'

export default ({ loading, components }) => {
  const [hasMounted] = useDidMount()

  return !loading || hasMounted ? (
    components?.length > 0 ? (
      components?.sort((a, b) => { return a.title.localeCompare(b.title)}).map(component => (
        <Component key={component.id} component={component} />
      ))
    ) : (
      <p>No Components found.</p>
    )
  ) : (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  )
}
