export default {
  operational: {
    message: 'All Systems Operational',
    backgroundColour: '#3da751',
  },
  issues: {
    message: 'Some systems are experiencing issues',
    backgroundColour: '#4990e2',
  },
  outage: {
    message: 'Some systems are experiencing a major outage',
    backgroundColour: '#d94430',
  },
}
